import './App.css';
import "./globals.css"

import { TitleEscrowFactoryABI, TitleEscrowFactoryBytecode } from './consts/TitleEscrowFactory';
import { TradeTrustTokenABI, TradeTrustTokenBytecode } from './consts/TradeTrustToken';
import { Wallet, ethers } from 'ethers';
import { useRef, useState } from "react";

import { createBillofLadingObject } from './BillofLading';
import { createCertificateOfOriginObject } from './CertificateOfOrigin';
import { createCommercialInvoiceObject } from './CommercialInvoice';
import { createHouseBillOfLadingObject } from './HouseBillOfLading';

import logo from "./docushieldlogo.png"

function App() {
  const dnsRecordRef = useRef(null);
  // State hooks
  // const [rightCharAmt, setRightCharAmt] = useState("")
  const [privateKey, setPrivateKey] = useState("");
  const [password, setPassword] = useState("");
  const [configJSON, setConfigJSON] = useState("");
  const [dnsTxt, setDnsTxt] = useState("");
  const [proofValue, setProofValue] = useState("");
  // const [issuerId, setIssuerId] = useState("");

  const [isRightCharAmt, setIsRightCharAmt] = useState(true);  
  const [isPrivateKeyValid, setIsPrivateKeyValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  // const [isIssuerIdValid, setIsIssuerIdValid] = useState(true);
  const [isDnsTxtValid, setIsDnsTxtValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(""); 
  const [finished, setFinished] = useState(false);

  const [progress, setProgress] = useState(0);

  // Constants
  const network = "xdc";


  const { Wallet } = require('ethers');

  async function initializeWallet() {
    const wallet = new Wallet(privateKey);
    const walletJson = await wallet.encrypt(password, {
        // Specify the encryption options that TradeTrust requires
    });

    const parsedWalletJson = JSON.parse(walletJson);
    // Modify the parsed JSON here as per TradeTrust's requirements

    return JSON.stringify(parsedWalletJson, null, 2);
}
  const validateInputs = () => {
    let isValid = true;
    setIsPrivateKeyValid(privateKey !== '');
    setIsPasswordValid(password !== '');
    // setIsIssuerIdValid(issuerId !== '');
    setIsDnsTxtValid(dnsTxt !== '');
    var rightCharAmt = privateKey;
    //check if private key is 64 characters
    if (rightCharAmt.length === 64) {
      setIsRightCharAmt(true);
    } else {
      setIsRightCharAmt(false);
    }

    if (!privateKey || !password || !dnsTxt || !isRightCharAmt) {
      isValid = false;
      setErrorMessage('Please fill out all fields.');
    } else {
      setErrorMessage('');
    }

    return isValid;
  };

  // function that adds https:// to the beginning of a url 
  function addHttps(url) {
    var newUrl = "https://" + url;
    return newUrl;
  }
    

  // Function to deploy TradeTrustToken
// Function to deploy TradeTrustToken
async function deployTradeTrustToken() {
  const xdcApothemRpcUrls = [
      "https://rpc.ankr.com/xdc",
      "https://rpc1.xinfin.network",
      "https://rpc.xdcrpc.com",
      "https://rpc.xdc.org"
  ];

  let provider;
  for (let url of xdcApothemRpcUrls) {
      try {
          provider = new ethers.providers.JsonRpcProvider(url);
          await provider.getBlockNumber();  // Try to fetch a block number to confirm the provider works
          console.log(`Connected to provider: ${url}`);
          break;
      } catch (error) {
          setLoadingMessage("Failed to connect to provider, trying another...")
          console.error(`Failed to connect to provider: ${url}`, error);
      }
  }

  if (!provider) {
      throw new Error("Failed to connect to any RPC provider.");
  }

  const wallet = new Wallet(privateKey, provider);
  console.log("wallet address:", wallet.address);
  setLoadingMessage("Deploying Title Escrow Factory Contract...");
  setProgress(20);
  const escrowFactory = new ethers.ContractFactory(TitleEscrowFactoryABI, TitleEscrowFactoryBytecode, wallet);
  const escrowFactoryContract = await escrowFactory.deploy();
  await escrowFactoryContract.deployed();
  console.log("Escrow Factory Contract deployed to:", escrowFactoryContract.address);
  setLoadingMessage("Deploying Token Registry Contract...");
  setProgress(50);
  const tradeTrustToken = new ethers.ContractFactory(TradeTrustTokenABI, TradeTrustTokenBytecode, wallet);
  var issuerIdhttps = addHttps(dnsTxt);
  console.log("issuerIdhttps:", issuerIdhttps);
  const tradeTrustTokenContract = await tradeTrustToken.deploy(issuerIdhttps, "DTG", escrowFactoryContract.address);
  await tradeTrustTokenContract.deployed();
  console.log("TradeTrustToken Contract deployed to:", tradeTrustTokenContract.address);
  setProgress(80);
  return tradeTrustTokenContract.address;
}

 

  // Function to handle sign-up
  async function handleSignUp() {
    if (!validateInputs()) {
      return;
    }
    setIsLoading(true); // Start loading
    try {
      const tradeTrustTokenAddress = await deployTradeTrustToken();
      setProofValue(tradeTrustTokenAddress);
      setLoadingMessage("Creating Config JSON...");
      const configJsonResult = await createConfigJSON(tradeTrustTokenAddress);
      // setLoadingMessage("Uploading to IPFS...");
      // await uploadToIPFS(configJsonResult,tradeTrustTokenAddress);
    } catch (error) {
      console.error("An error occurred during sign up:", error);
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome (add error handling in the future)
      setFinished(true);
    }
  }
  
 // Function to create Config JSON
const createConfigJSON = async (tradeTrustTokenAddress) => {
  const encryptedWalletJson = await initializeWallet();
  var issuerIdhttps = addHttps(dnsTxt);
  // Creating the Bill of Lading object with the updated structure
  const billOfLadingForm = createBillofLadingObject(tradeTrustTokenAddress, dnsTxt, issuerIdhttps);
  const CertificateOfOriginForm = createCertificateOfOriginObject(tradeTrustTokenAddress, dnsTxt, issuerIdhttps);
  const CommercialInvoiceForm = createCommercialInvoiceObject(tradeTrustTokenAddress, dnsTxt, issuerIdhttps);
  const HouseBillOfLadingForm = createHouseBillOfLadingObject(tradeTrustTokenAddress, dnsTxt, issuerIdhttps);


  // Constructing the config object with the new structure
  const config = {
    network: network, // Make sure the network is in uppercase as required by the new structure
    wallet: {
      type: "ENCRYPTED_JSON",
      encryptedJson: encryptedWalletJson,
    },
    forms: [
      {
        ...billOfLadingForm // Spread the Bill of Lading form object here
      },
      {
        ...CertificateOfOriginForm
      },
      {
        ...CommercialInvoiceForm
      },
      {
        ...HouseBillOfLadingForm
      }
    ]
  };

  // Stringifying the config object to prepare for setting in state or other uses
  const configStr = JSON.stringify(config, null, 2);
  setConfigJSON(configStr); // Updating the state with the new config JSON string
  console.log("Config JSON:", configStr);
  setProgress(100);
  return configStr; // Optionally return the JSON string for other purposes, like IPFS upload
};



  const copyToClipboard = () => {
    if (dnsRecordRef.current) {
      navigator.clipboard.writeText(dnsRecordRef.current.innerText)
        .then(() => {
          // Success message or logic
          alert('DNS Record copied to clipboard!');
        })
        .catch((err) => {
          // Error handling
          console.error('Failed to copy text: ', err);
        });
      }
  };

  const downloadConfig = () => {
    const element = document.createElement("a");
    const file = new Blob([configJSON], { type: 'text/json' });
    element.href = URL.createObjectURL(file);
    element.download = "config.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    };

    
  // UI Render

  return (
    <>
      <header>
        <img src={logo} className="logo" />
      </header>
      <div style={{ height: "100%" }} className="main-container">
      {errorMessage && (
        <div className="error-modal">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}>Close</button>
        </div>
      )}
        {!finished && (
          <div>
            <br />
            <h1>DocuShield Sign up</h1>
            We have a written guide here:&nbsp;
            <a href="https://docushield-pilot-guide.gitbook.io/signup/">Docushield Pilot Guide</a>
            <br />
            <br />
            <h3>Please fill out all fields</h3>
            <b>Wallet private key </b> <br/>
            <i>64 characters long, NOT your public key nor your mnemonic phrase</i>
            <br />
            <input
              className={isPrivateKeyValid ? "" : "error"}
              disabled={isLoading}
              type="text"
              onChange={(e) => setPrivateKey(e.target.value)}
            />
            {!isPrivateKeyValid && <span className="error-message">Private key is required</span>}
            {!isRightCharAmt && <span className="error-message">64 characters required</span>}
            <br />
            <br />

            <br></br>
            <b>Password for DocuShield</b>
            <br />
            <i>Please make it a good password, usually 4 words long is good</i> <br/>
            <input
              className={isPasswordValid ? "" : "error"}
              disabled={isLoading}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {!isPasswordValid && <span className="error-message">Password is required</span>}
            <br /> <br />
            <br />
            <b>Website name</b> <br />
            example: <u>example.com</u>
            <br />
            <i>Do NOT include www. nor https://</i> <br/>
            <input
              className={isDnsTxtValid ? "" : "error"}
              disabled={isLoading}
              type="text"
              onChange={(e) => setDnsTxt(e.target.value)}
            />
             {!isDnsTxtValid && <span className="error-message">Website name is required</span>}
            <br />
            <br />
            <br />
            {isLoading && (
              <>{progress} %
              <br />
              <progress value={progress} max="100">{progress}%</progress>
              <br/>
                Processing, please wait...
              <br/>
              
                  {loadingMessage}
              <br />
                
                
              </>
            )}
            <br />
            <button
              className="signUpButton"
              onClick={handleSignUp}
              disabled={isLoading}
            >
              Sign Up
            </button>
          </div>
        )}
        {finished && (
          <>
            <h1>Thank you for signing up!</h1>
            {/* 
      Your ID number to sign into DocuShield is:
      <h1> {userId} </h1> */}
            Your password is: <h1>{password}</h1>
            <div style={{ color: "red" }}>
              <h1>IMPORTANT</h1>
            </div>
            Please update your DNS TXT record to: <br />
            <div
              ref={dnsRecordRef}
              style={{
                color: "black",
                background: "white",
                padding: "5px",
                maxWidth: "700px",
                borderRadius: "5px",
              }}
            >
              "openatts net=ethereum netId=50 addr={proofValue}"
            </div>{" "}
            <button onClick={copyToClipboard}>Copy to Clipboard</button>
            <br />
            <br />
            <br />
            <h3>Please download this file, you need it in order to log in</h3>
            {/* Button to download config.json */}
            <button onClick={downloadConfig}>Download Config</button>
            <h1> Next steps</h1>
            go to{" "}
            <a href="https://create.docushield.live/">
            https://create.docushield.live/
            </a>{" "}
            to begin creating files
            
            
          </>
        )}
      </div>
    </>
  );
}

export default App;