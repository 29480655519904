// File: CommercialInvoice.js

export const network = "xdc";
export const xdcChainId = "50";

// Function that creates and returns a Commercial Invoice form object
export function createCommercialInvoiceObject(proofValue, dnsTxt, issuerId) {
  const currentDate = new Date();
  const issuanceDate = currentDate.toISOString();

  const COMMERCIAL_INVOICE_FORM = {
    "name": "Commercial Invoice",
    "type": "TRANSFERABLE_RECORD",
    "defaults": {
      "$template": {
        "type": "EMBEDDED_RENDERER",
        "name": "CommercialInvoice",
        "url": "https://document-renderer.vercel.app/"
      },
      "issuers": [
        {
          "name": issuerId,
          "tokenRegistry": proofValue,
          "identityProof": {
            "type": "DNS-TXT",
            "location": dnsTxt
          },
          "revocation": {
            "type": "NONE"
          }
        }
      ],
      "network": {
        "chain": "xdc",
        "chainId": "50"
      }
    },
    "schema": {
      "type": "object",
      "additionalProperties": false,
      "properties": {
        "AirWaybillNo": {
          "type": "string"
        },
        "ExportReferences": {
          "type": "string"
        },
        "DateOfExportation": {
          "type": "string"
        },
        "CountryOfExport": {
          "type": "string"
        },
        "ReasonForExport": {
          "type": "string"
        },
        "CountryOfDestination": {
          "type": "string"
        },
        "CountryOfOrigin": {
          "type": "string"
        },
        "MarksNumbers": {
          "type": "string"
        },
        "Packages": {
          "type": "integer"
        },
        "PackagingType": {
          "type": "string"
        },
        "TotalPackages": {
          "type": "integer"
        },
        "Goods": {
          "type": "array",
          "items": {
            "type": "object",
            "properties": {
              "Description": {
                "type": "string"
              },
              "HSCode": {
                "type": "string"
              },
              "Quantity": {
                "type": "number"
              },
              "UnitOfMeasure": {
                "type": "string"
              },
              "Weight": {
                "type": "number"
              },
              "UnitValue": {
                "type": "number"
              },
              "TotalValue": {
                "type": "number"
              }
            }
          }
        },
        "TotalInvoiceValue": {
          "type": "number"
        },
        "Currency": {
          "type": "string"
        },
        "PaymentMethod": {
          "type": "string"
        },
        "PricingTerms": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "Shipper": {
          "type": "object",
          "properties": {
            "CompanyName": {
              "type": "string"
            },
            "Address": {
              "type": "object",
              "properties": {
                "Street": {
                  "type": "string"
                },
                "City": {
                  "type": "string"
                },
                "State": {
                  "type": "string"
                },
                "PostalCode": {
                  "type": "string"
                },
                "Country": {
                  "type": "string"
                }
              }
            },
            "ContactName": {
              "type": "string"
            },
            "Phone": {
              "type": "string"
            },
            "BusinessRegistrationNo": {
              "type": "string"
            }
          }
        },
        "Buyer": {
          "type": "object",
          "properties": {
            "CompanyName": {
              "type": "string"
            },
            "Address": {
              "type": "object",
              "properties": {
                "Street": {
                  "type": "string"
                },
                "City": {
                  "type": "string"
                },
                "State": {
                  "type": "string"
                },
                "PostalCode": {
                  "type": "string"
                },
                "Country": {
                  "type": "string"
                }
              }
            },
            "ContactName": {
              "type": "string"
            },
            "Phone": {
              "type": "string"
            },
            "BusinessRegistrationNo": {
              "type": "string"
            }
          }
        },
        "Consignee": {
          "type": "object",
          "properties": {
            "CompanyName": {
              "type": "string"
            },
            "Address": {
              "type": "object",
              "properties": {
                "Street": {
                  "type": "string"
                },
                "City": {
                  "type": "string"
                },
                "State": {
                  "type": "string"
                },
                "PostalCode": {
                  "type": "string"
                },
                "Country": {
                  "type": "string"
                }
              }
            },
            "ContactName": {
              "type": "string"
            },
            "Phone": {
              "type": "string"
            },
            "BusinessRegistrationNo": {
              "type": "string"
            }
          }
        },
        "Importer": {
          "type": "object",
          "properties": {
            "CompanyName": {
              "type": "string"
            },
            "Address": {
              "type": "object",
              "properties": {
                "Street": {
                  "type": "string"
                },
                "City": {
                  "type": "string"
                },
                "State": {
                  "type": "string"
                },
                "PostalCode": {
                  "type": "string"
                },
                "Country": {
                  "type": "string"
                }
              }
            },
            "ContactName": {
              "type": "string"
            },
            "Phone": {
              "type": "string"
            },
            "BusinessRegistrationNo": {
              "type": "string"
            }
          }
        },
        "Certification": {
          "type": "object",
          "properties": {
            "Signature": {
              "type": "string"
            },
            "Name": {
              "type": "string"
            },
            "Title": {
              "type": "string"
            },
            "Date": {
              "type": "string"
            }
          }
        }
      }
    }
  };
  
  return COMMERCIAL_INVOICE_FORM;
}
