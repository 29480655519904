// File: HouseBillOfLading.js

export const network = "xdc";
export const xdcChainId = "50";

// Function that creates and returns a House Bill of Lading form object
export function createHouseBillOfLadingObject(proofValue, dnsTxt, issuerId) {
  const currentDate = new Date();
  const issuanceDate = currentDate.toISOString();

  const HOUSE_BILL_OF_LADING_FORM = {
    "name": "House Bill of Lading",
    "type": "TRANSFERABLE_RECORD",
    "defaults": {
      "$template": {
        "type": "EMBEDDED_RENDERER",
        "name": "HouseBillOfLading",
        "url": "https://document-renderer.vercel.app/"
      },
      "issuers": [
        {
          "name": issuerId,
          "tokenRegistry": proofValue,
          "identityProof": {
            "type": "DNS-TXT",
            "location": dnsTxt
          },
          "revocation": {
            "type": "NONE"
          }
        }
      ],
      "network": {
        "chain": "xdc",
        "chainId": "50"
      }
    },
    "schema": {
      "type": "object",
      "additionalProperties": false,
      "properties": {
        "HouseBillOfLadingNo": {
          "type": "string"
        },
        "Shipper": {
          "type": "object",
          "properties": {
            "CompanyName": {
              "type": "string"
            },
            "Address": {
              "type": "object",
              "properties": {
                "Street": {
                  "type": "string"
                },
                "City": {
                  "type": "string"
                },
                "State": {
                  "type": "string"
                },
                "PostalCode": {
                  "type": "string"
                },
                "Country": {
                  "type": "string"
                }
              }
            },
            "ContactName": {
              "type": "string"
            },
            "Phone": {
              "type": "string"
            },
            "Email": {
              "type": "string"
            }
          }
        },
        "Consignee": {
          "type": "object",
          "properties": {
            "CompanyName": {
              "type": "string"
            },
            "Address": {
              "type": "object",
              "properties": {
                "Street": {
                  "type": "string"
                },
                "City": {
                  "type": "string"
                },
                "State": {
                  "type": "string"
                },
                "PostalCode": {
                  "type": "string"
                },
                "Country": {
                  "type": "string"
                }
              }
            },
            "ContactName": {
              "type": "string"
            },
            "Phone": {
              "type": "string"
            },
            "Email": {
              "type": "string"
            }
          }
        },
        "ThirdPartyBillTo": {
          "type": "object",
          "properties": {
            "CompanyName": {
              "type": "string"
            },
            "Address": {
              "type": "object",
              "properties": {
                "Street": {
                  "type": "string"
                },
                "City": {
                  "type": "string"
                },
                "State": {
                  "type": "string"
                },
                "PostalCode": {
                  "type": "string"
                },
                "Country": {
                  "type": "string"
                }
              }
            },
            "ContactName": {
              "type": "string"
            },
            "Phone": {
              "type": "string"
            },
            "Email": {
              "type": "string"
            }
          }
        },
        "Service": {
          "type": "string"
        },
        "ServiceLevel": {
          "type": "string"
        },
        "ShipperRefNo": {
          "type": "string"
        },
        "ConsigneeRefNo": {
          "type": "string"
        },
        "UnitsAndContainer": {
          "type": "string"
        },
        "Goods": {
          "type": "array",
          "items": {
            "type": "object",
            "properties": {
              "ProperShippingName": {
                "type": "string"
              },
              "HazardClass": {
                "type": "string"
              },
              "IdentificationNumber": {
                "type": "string"
              },
              "PackagingGroup": {
                "type": "string"
              },
              "Weight": {
                "type": "number"
              },
              "Dimensions": {
                "type": "string"
              }
            }
          },
          "default": []
        },
        "FreightCharges": {
          "type": "object",
          "properties": {
            "FreightPrepaid": {
              "type": "boolean"
            },
            "ChargesCollect": {
              "type": "boolean"
            }
          }
        },
        "CODAmount": {
          "type": "number"
        },
        "CODFee": {
          "type": "number"
        },
        "TotalCharges": {
          "type": "number"
        },
        "ShipperSignature": {
          "type": "string"
        },
        "CarrierSignature": {
          "type": "string"
        },
        "Date": {
          "type": "string"
        }
      }
    }
  };

  return HOUSE_BILL_OF_LADING_FORM;
}
