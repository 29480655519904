export const network = "xdc";
export const xdcChainId = "50";

// Function that creates and returns a Certificate of Origin form object
export function createCertificateOfOriginObject(proofValue, dnsTxt, issuerId) {
  const NEW_CERTIFICATE_OF_ORIGIN_FORM = {
    "name": "Certificate of Origin",
    "type": "TRANSFERABLE_RECORD",
    "defaults": {
      "$template": {
        "type": "EMBEDDED_RENDERER",
        "name": "CertificateOfOrigin",
        "url": "https://document-renderer.vercel.app/"
      },
      "issuers": [
        {
          "name": issuerId,
          "tokenRegistry": proofValue,
          "identityProof": {
            "type": "DNS-TXT",
            "location": dnsTxt
          },
          "revocation": {
            "type": "NONE"
          }
        }
      ],
      "network": {
        "chain": "xdc",
        "chainId": "50"
      }
    },
    "schema": {
      "type": "object",
      "additionalProperties": false,
      "properties": {
        "Exporter": {
          "type": "object",
          "properties": {
            "CompanyName": { "type": "string" },
            "PhysicalAddress": {
              "type": "object",
              "properties": {
                "Street": { "type": "string" },
                "City": { "type": "string" },
                "State": { "type": "string" },
                "PostalCode": { "type": "string" },
                "Country": { "type": "string" }
              }
            },
            "ContactName": { "type": "string" },
            "EmailOrFax": { "type": "string" },
            "Phone": { "type": "string" }
          }
        },
        "ExportInvoiceNumber": { "type": "string" },
        "ExportInvoiceDate": { "type": "string" },
        "LetterOfCreditNo": { "type": "string" },
        "Consignee": {
          "type": "object",
          "properties": {
            "CompanyName": { "type": "string" },
            "PhysicalAddress": {
              "type": "object",
              "properties": {
                "Street": { "type": "string" },
                "City": { "type": "string" },
                "State": { "type": "string" },
                "PostalCode": { "type": "string" },
                "Country": { "type": "string" }
              }
            },
            "ContactName": { "type": "string" },
            "EmailOrFax": { "type": "string" },
            "Phone": { "type": "string" }
          }
        },
        "Buyer": {
          "type": "object",
          "properties": {
            "CompanyName": { "type": "string" },
            "PhysicalAddress": {
              "type": "object",
              "properties": {
                "Street": { "type": "string" },
                "City": { "type": "string" },
                "State": { "type": "string" },
                "PostalCode": { "type": "string" },
                "Country": { "type": "string" }
              }
            },
            "ContactName": { "type": "string" },
            "EmailOrFax": { "type": "string" },
            "Phone": { "type": "string" }
          }
        },
        "MethodOfDispatch": { "type": "string" },
        "TypeOfShipment": { "type": "string" },
        "VesselOrAircraft": { "type": "string" },
        "VoyageNo": { "type": "string" },
        "PortOfLoading": { "type": "string" },
        "DateOfDeparture": { "type": "string" },
        "PortOfDischarge": { "type": "string" },
        "FinalDestination": { "type": "string" },
        "MarksAndNumbers": { "type": "string" },
        "DescriptionOfGoods": { "type": "string" },
        "TariffCode": { "type": "string" },
        "GrossWeight": { "type": "string" },
        "PlaceAndDateOfIssue": { "type": "string" },
        "SignatoryCompany": { "type": "string" },
        "NameOfAuthorizedSignatory": { "type": "string" },
        "Signature": { "type": "string" }
      }
    }
  };

  return NEW_CERTIFICATE_OF_ORIGIN_FORM;
}
