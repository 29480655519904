export const network = "xdc";
export const xdcChainId = "50";

/// function that creates and returns a form object
  export function createBillofLadingObject(proofValue, dnsTxt, issuerId) {

    const NEW_BILL_OF_LADING_FORM =     {
      "name": "Bill of Lading",
      "type": "TRANSFERABLE_RECORD",
      "defaults": {
        "$template": {
          "type": "EMBEDDED_RENDERER",
          "name": "BillOfLading",
          "url": "https://document-renderer.vercel.app/"
        },
        "issuers": [
          {
            "name": issuerId,
            "tokenRegistry": proofValue,
            "identityProof": {
              "type": "DNS-TXT",
              "location": dnsTxt
            },
            "revocation": {
              "type": "NONE"
            }
          }
        ],
        "network": {
          "chain": "xdc",
          "chainId": "50"
        }
      },
      "schema": {
        "type": "object",
        "additionalProperties": false,
        "properties": {
          "Logo": {
            "type": "string"
          },
          "SCAC": {
            "type": "string"
          },
          "BillOfLadingNo": {
            "type": "string"
          },
          "Shipper": {
            "type": "object",
            "properties": {
              "CompanyName": {
                "type": "string"
              },
              "PhysicalAddress": {
                "type": "object",
                "properties": {
                  "Street": {
                    "type": "string"
                  },
                  "City": {
                    "type": "string"
                  },
                  "State": {
                    "type": "string"
                  },
                  "PostalCode": {
                    "type": "string"
                  },
                  "Country": {
                    "type": "string"
                  }
                }
              },
              "ContactName": {
                "type": "string"
              },
              "EmailOrFax": {
                "type": "string"
              },
              "Phone": {
                "type": "string"
              },
              "LEIorTaxID": {
                "type": "string"
              }
            }
          },
          "BookingNo": {
            "type": "string"
          },
          "ExportReferences": {
            "type": "string"
          },
          "OnwardInlandRouting": {
            "type": "string"
          },
          "Consignee": {
            "type": "object",
            "properties": {
              "CompanyName": {
                "type": "string"
              },
              "PhysicalAddress": {
                "type": "object",
                "properties": {
                  "Street": {
                    "type": "string"
                  },
                  "City": {
                    "type": "string"
                  },
                  "State": {
                    "type": "string"
                  },
                  "PostalCode": {
                    "type": "string"
                  },
                  "Country": {
                    "type": "string"
                  }
                }
              },
              "ContactName": {
                "type": "string"
              },
              "EmailOrFax": {
                "type": "string"
              },
              "Phone": {
                "type": "string"
              },
              "LEIorTaxID": {
                "type": "string"
              }
            }
          },
          "NotifyParty": {
            "type": "object",
            "properties": {
              "CompanyName": {
                "type": "string"
              },
              "PhysicalAddress": {
                "type": "object",
                "properties": {
                  "Street": {
                    "type": "string"
                  },
                  "City": {
                    "type": "string"
                  },
                  "State": {
                    "type": "string"
                  },
                  "PostalCode": {
                    "type": "string"
                  },
                  "Country": {
                    "type": "string"
                  }
                }
              },
              "ContactName": {
                "type": "string"
              },
              "EmailOrFax": {
                "type": "string"
              },
              "Phone": {
                "type": "string"
              },
              "LEIorTaxID": {
                "type": "string"
              }
            }
          },
          "Vessel": {
            "type": "string"
          },
          "VoyageNo": {
            "type": "string"
          },
          "PlaceOfReceipt": {
            "type": "string"
          },
          "PortOfLoading": {
            "type": "object",
            "properties": {
              "Location": {
                "type": "string"
              },
              "UNLocode": {
                "type": "string"
              }
            }
          },
          "PortOfDischarge": {
            "type": "object",
            "properties": {
              "Location": {
                "type": "string"
              },
              "UNLocode": {
                "type": "string"
              }
            }
          },
          "PlaceOfDelivery": {
            "type": "string"
          },
          "ContainerDetails": {
            "type": "array",
            "items": {
              "type": "object",
              "properties": {
                "Number": {
                  "type": "string"
                },
                "SealNumber": {
                  "type": "string"
                },
                "Description": {
                  "type": "string"
                },
                "Weight": {
                  "type": "string"
                },
                "Measurement": {
                  "type": "string"
                }
              }
            }
          },
          "FreightDetails": {
            "type": "object",
            "properties": {
              "FreightAndCharges": {
                "type": "string"
              },
              "Rate": {
                "type": "string"
              },
              "Unit": {
                "type": "string"
              },
              "Currency": {
                "type": "string"
              },
              "Prepaid": {
                "type": "string"
              },
              "Collect": {
                "type": "string"
              }
            }
          },
          "CarrierReceipt": {
            "type": "string"
          },
          "PlaceOfIssue": {
            "type": "string"
          },
          "DateOfIssue": {
            "type": "string"
          },
          "NumberOfOriginalBLs": {
            "type": "integer"
          },
          "DeclaredValue": {
            "type": "string"
          },
          "ShippedOnBoardDate": {
            "type": "string"
          },
          "SignedBy": {
            "type": "string"
          },
          "ShippedOnDeck": {
            "type": "object",
            "properties": {
              "NumberOrWeight": {
                "type": "string"
              },
              "Description": {
                "type": "string"
              }
            }
          }
        }
      }
    }

    return NEW_BILL_OF_LADING_FORM;

}
